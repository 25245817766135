import {brandConfigObjectMap,} from "./brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('vc-za-mg'));
brandConfigMap.set('http://192.168.88.91:8080', getBrandConfig('vc-za-u3'));
brandConfigMap.set('https://web.quizinc.co.za', getBrandConfig('vc-za-qi'));
brandConfigMap.set('https://staging-web.quizinc.co.za', getBrandConfig('vc-za-qi'));
brandConfigMap.set('https://play.quizinc.co.za', getBrandConfig('vc-za-qi-encrypt'));
brandConfigMap.set('https://staging-play.quizinc.co.za', getBrandConfig('vc-za-qi-encrypt'));
brandConfigMap.set('https://web.unlock3.co.za', getBrandConfig('vc-za-u3'));
brandConfigMap.set('https://staging-web.unlock3.co.za', getBrandConfig('vc-za-u3'));
brandConfigMap.set('https://play.unlock3.co.za', getBrandConfig('vc-za-u3-encrypt'));
brandConfigMap.set('https://staging-play.unlock3.co.za', getBrandConfig('vc-za-u3-encrypt'));
brandConfigMap.set('https://web.stopclock.co.za', getBrandConfig('vc-za-stc'));
brandConfigMap.set('https://staging-web.stopclock.co.za', getBrandConfig('vc-za-stc'));
brandConfigMap.set('https://play.stopclock.co.za', getBrandConfig('vc-za-stc-encrypt'));
brandConfigMap.set('https://staging-play.stopclock.co.za', getBrandConfig('vc-za-stc-encrypt'));
brandConfigMap.set('https://web.quizhead2head.co.za', getBrandConfig('vc-za-h2h'));
brandConfigMap.set('https://staging-web.quizhead2head.co.za', getBrandConfig('vc-za-h2h'));
brandConfigMap.set('https://play.quizhead2head.co.za', getBrandConfig('vc-za-h2h-encrypt'));
brandConfigMap.set('https://staging-play.quizhead2head.co.za', getBrandConfig('vc-za-h2h-encrypt'));
brandConfigMap.set('https://web.vodacomfantasy.com', getBrandConfig('vc-za-ff'));
brandConfigMap.set('https://web.beatthejams.co.za', getBrandConfig('vc-za-btj'));
brandConfigMap.set('https://staging-web.beatthejams.co.za', getBrandConfig('vc-za-btj'));
brandConfigMap.set('https://play.beatthejams.co.za', getBrandConfig('vc-za-btj-encrypt'));
brandConfigMap.set('https://staging-play.beatthejams.co.za', getBrandConfig('vc-za-btj-encrypt'));
brandConfigMap.set('https://web.fantasyfootball.co.za', getBrandConfig('vc-za-ff'));
brandConfigMap.set('https://staging-web.fantasyfootball.co.za', getBrandConfig('vc-za-ff'));
brandConfigMap.set('https://web.masterstream.co.za', getBrandConfig('vc-za-ms'));
brandConfigMap.set('https://staging-web.masterstream.co.za', getBrandConfig('vc-za-ms'));
brandConfigMap.set('https://play.masterstream.co.za', getBrandConfig('vc-za-ms-encrypt'));
brandConfigMap.set('https://masterstream.penrosegaming.co.za', getBrandConfig('vc-za-ms-encrypt'));
brandConfigMap.set('https://staging-play.masterstream.co.za', getBrandConfig('vc-za-ms-encrypt'));
brandConfigMap.set('https://staging-masterstream.penrosegaming.co.za', getBrandConfig('vc-za-ms-encrypt'));
brandConfigMap.set('https://staging-masterstream.penrosegaming.co.za', getBrandConfig('vc-za-ms-encrypt'));
brandConfigMap.set('https://web.mzansigames.club', getBrandConfig('vc-za-mg'));
brandConfigMap.set('https://staging-web.mzansigames.club', getBrandConfig('vc-za-mg'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        gameTitle: brandConfigObject.gameTitle,
        gameTag: brandConfigObject.gameTag,
        serviceId: brandConfigObject.serviceId,
        weeklyServiceId: brandConfigObject.weeklyServiceId,
        termsConditionsTitle: brandConfigObject.termsConditionsTitle,
        gameUrl: brandConfigObject.gameUrl,
        termsConditionsUrl: brandConfigObject.termsConditionsUrl,
        multiTierService: brandConfigObject.multiTierService,
        productId: brandConfigObject.productId,
        freeServiceId: brandConfigObject.freeServiceId,
        ussdString: brandConfigObject.ussdString,
        baseRoute: brandConfigObject.baseRoute,
        revenuePartnerId: brandConfigObject.revenuePartnerId,
        prizePool: brandConfigObject.prizePool,
        pricepoint: brandConfigObject?.pricepoint,
        weeklyPricepoint: brandConfigObject?.weeklyPricepoint,
        disclaimer: brandConfigObject?.disclaimer,
        imageUrl: brandConfigObject?.imageUrl,
        rawPricePoint: brandConfigObject?.rawPricePoint
    }
}
